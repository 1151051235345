import { useParams } from "next/navigation";
import type { Locale } from "~/lib/i18n/types";
import type { Currency } from "~/lib/store-config/context";
import { useStoreConfig } from "~/lib/store-config/context";

export const useStoreContext = (): {
	storeKey: string;
	currency: Currency;
	locale: Locale;
} => {
	const storeConfig = useStoreConfig();
	const params = useParams();
	const locale =
		storeConfig.locales.find((locale) => locale === params?.locale) ??
		storeConfig.defaultLocale;

	const storeContext = {
		storeKey: storeConfig.storeKey,
		currency: storeConfig.currency,
		locale,
	};

	return storeContext;
};
