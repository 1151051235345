import type { InputHTMLAttributes } from "react";
import { forwardRef } from "react";
import { cn } from "../../helpers/styles";

export type InputProps = InputHTMLAttributes<HTMLInputElement>;

export const Input = forwardRef<HTMLInputElement, InputProps>(
	({ className, ...props }, ref) => {
		return (
			<input
				className={cn(
					// reset
					"appearance-none",
					// shape
					"w-full rounded-md border border-gray-300 bg-white px-4 py-2.5 shadow-sm disabled:cursor-not-allowed disabled:opacity-50",
					// text
					"text-sm placeholder:text-gray-400 md:text-base",
					// file
					"file:border-0 file:bg-transparent file:font-medium file:text-sm",
					// className from props
					className,
				)}
				ref={ref}
				{...props}
			/>
		);
	},
);

Input.displayName = "Input";
