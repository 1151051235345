import(/* webpackMode: "eager" */ "/app/frontend/site/image-loader.js");
;
import(/* webpackMode: "eager", webpackExports: ["MobileAccordion"] */ "/app/frontend/site/src/app/[locale]/(main)/_components/Footer/MobileAccordion/MobileAccordion.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AccountLink"] */ "/app/frontend/site/src/app/[locale]/(main)/_components/Header/AccountLink/AccountLink.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AlgoliaSearch"] */ "/app/frontend/site/src/app/[locale]/(main)/_components/Header/algolia-search.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/frontend/site/src/app/[locale]/(main)/_components/Header/CartLink/CartLink.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/frontend/site/src/app/[locale]/(main)/_components/Header/DesktopNavigation/DesktopNavigation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HamburgerNavigation"] */ "/app/frontend/site/src/app/[locale]/(main)/_components/Header/HamburgerNavigation/HamburgerNavigation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LanguageSwitcherDesktopFallback","LanguageSwitcherDesktop"] */ "/app/frontend/site/src/app/[locale]/(main)/_components/Header/LanguageSwitcherDesktop.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NewsletterForm"] */ "/app/frontend/site/src/app/[locale]/(main)/_components/NewsletterForm/NewsletterForm.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ScreenSize"] */ "/app/frontend/site/src/components/Development/ScreenSize.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Link"] */ "/app/frontend/site/src/components/Link/Link.tsx");
;
import(/* webpackMode: "eager" */ "/app/frontend/site/src/lib/store-config/context/storeConfig.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ReactQueryDevtools"] */ "/app/node_modules/.pnpm/@tanstack+react-query-devtools@5.51.21_@tanstack+react-query@5.44.0_react@18.3.1__react@18.3.1/node_modules/@tanstack/react-query-devtools/build/modern/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/.pnpm/next-intl@3.19.0_next@14.2.8_@babel+core@7.25.2_@opentelemetry+api@1.9.0_@playwright+test@1.4_5sh53aeuo7ovizvj2vknolqwwu/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/.pnpm/next-intl@3.19.0_next@14.2.8_@babel+core@7.25.2_@opentelemetry+api@1.9.0_@playwright+test@1.4_5sh53aeuo7ovizvj2vknolqwwu/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.8_@babel+core@7.25.2_@opentelemetry+api@1.9.0_@playwright+test@1.45.3_react-dom@18._xqsjgrumfhzfigaj5fj2fbsbxa/node_modules/next/dist/client/image-component.js");
