"use client";

import { useLocale } from "next-intl";
import { useParams } from "next/navigation";
import { useEffect, useState } from "react";
import { routing, usePathname } from "./navigation";
import type { Locale } from "./types";

type Alternate = {
	locale: Intl.Locale;
	href: string;
};

/**
 * Get alternate paths for the current page
 * This will try to get the alternate pages from the alternate hreflangs in the head tag
 * but fall back to generating the paths based on the current path and the available locales with `next-intl`.
 */
export const useAlternates = (locales: Locale[]): Alternate[] => {
	const [alternates, setAlternates] = useState<Alternate[]>([]);
	const pathname = usePathname();
	const params = useParams();
	const locale = useLocale() as Locale;

	const localePrefix = new Map<string, string>();

	// @ts-expect-error
	if (routing.localePrefix?.prefixes) {
		for (const loc of locales) {
			// @ts-expect-error
			localePrefix.set(loc, routing.localePrefix.prefixes[loc] ?? `/${loc}`);
		}
	}

	useEffect(() => {
		// Extract alternates from the head
		const headAlternates = Array.from(
			document.head.querySelectorAll("link[rel='alternate']"),
		).map((link) =>
			createAlternate(
				link.getAttribute("hreflang") ?? "",
				link.getAttribute("href") ?? "",
			),
		);

		const alternatesArray: Alternate[] = [];

		// Merge head alternates and additional locales
		for (const [locale, prefix] of localePrefix.entries()) {
			const headAlternate = headAlternates.find((alt) => alt.locale === locale);
			if (headAlternate?.locale && headAlternate?.href) {
				alternatesArray.push({
					locale: new Intl.Locale(headAlternate.locale),
					href: headAlternate.href,
				});
			} else {
				alternatesArray.push({
					locale: new Intl.Locale(locale),
					href: prefix,
				});
			}
		}

		setAlternates(alternatesArray);
	}, [locale, pathname, locales, params]);

	return alternates;
};

const relativePath = (href: string) => {
	const url = new URL(href, window.location.origin);
	return url.pathname + url.search;
};

const createAlternate = (loc: string, href: string) => ({
	locale: loc,
	href: relativePath(href),
});
