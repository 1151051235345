/**
 * Buffer time in seconds before token expiration.
 * Set to 5 minutes to allow ample time for token refresh before it becomes invalid.
 */
const TOKEN_VALID_BUFFER = 5 * 60;

/**
 * Checks the local JWT token stored in cookies.
 *
 * This function retrieves the JWT token using the getJWT function and validates its expiration.
 * It ensures that the token is still valid for at least 5 more minutes.
 *
 * @returns {Token | undefined} The decoded token if it's valid, or undefined if the token is expired or doesn't exist.
 *
 * @example
 * const token = checkLocalToken();
 * if (token) {
 *   console.log('User is authenticated:', token.authenticated);
 * } else {
 *   console.log('Token is expired or not present');
 * }
 */
export const checkLocalToken = (): Token | undefined => {
	const token = getJWT();

	if (!token) {
		return undefined;
	}

	return checkTokenValidity(token) ? token : undefined;
};

/**
 * Checks if the token is still valid for at least 5 more minutes.
 *
 * @param token The JWT token to check.
 * @returns {boolean} True if the token is still valid, false otherwise.
 */
const checkTokenValidity = (token: Token): boolean => {
	// Get the current time in seconds
	const timeSec = Math.floor(Date.now() / 1000);

	return Boolean(token?.exp && token.exp - TOKEN_VALID_BUFFER > timeSec);
};

import Cookie from "js-cookie";
import { decode as jwtDecode } from "jsonwebtoken";

export type Token = {
	exp: number;
	authenticated: boolean;
	firstName: string | undefined;
	lastName: string | undefined;
};

export const getJWT = (): Token | undefined => {
	const token = Cookie.get("authToken");
	if (!token) return undefined;

	return decodeToken(token);
};

export const decodeToken = (token: string): Token | undefined => {
	const decodedToken = jwtDecode(token, { complete: true });
	if (
		!decodedToken ||
		!decodedToken.payload ||
		typeof decodedToken.payload === "string"
	) {
		return undefined;
	}
	return decodedToken.payload as Token;
};

export const getAccessToken = async (
	apiHostname: string,
): Promise<Token | undefined> => {
	const token = getJWT();

	// Check if the token is still valid for more than 5 minutes. The JWT times
	// are in seconds, so we need to convert the current time to seconds as well.
	const timeSec = Math.floor(Date.now() / 1000);
	const buffer = 5 * 60;
	if (token?.exp && token.exp - buffer > timeSec) {
		return token;
	}

	// Do we have a refresh token?
	const hasRefreshToken = Cookie.get("authRefreshTokenExist");
	if (hasRefreshToken) {
		await refreshAccessToken(apiHostname);
		return getJWT();
	}

	// No token exists
	return undefined;
};

const refreshAccessToken = async (apiHostname: string): Promise<void> => {
	const query = "mutation { refreshToken { success } }";

	// Since we are storing the refresh token in a cookie this will be sent
	// automatically by the browser.
	const response = await fetch(`${apiHostname}/auth/graphql?op=refreshToken`, {
		method: "POST",
		body: JSON.stringify({ query }),
		headers: {
			"Content-Type": "application/json",
		},
		credentials: "include",
	});
	if (!response.ok) {
		throw new Error(`Failed to refresh token: ${response.statusText}`);
	}
};
