// Based on shadcn/ui Sheet
"use client";

import * as DialogPrimitive from "@radix-ui/react-dialog";
import type { VariantProps } from "class-variance-authority";
import { cva } from "class-variance-authority";
import { usePathname } from "next/navigation";
import React, { useEffect, useState } from "react";
import { cn } from "../../helpers/styles";

export const Dialog = (
	props: React.ComponentPropsWithoutRef<typeof DialogPrimitive.Root>,
) => {
	const [open, setOpen] = useState(false);
	const [key, setKey] = useState("closed");
	const pathname = usePathname();
	useEffect(() => {
		// Close the dialog when the pathname changes
		setOpen(false);
	}, [pathname]);

	// We abuse the key to rerender the contents when the menu is open or closed
	// This is needed to keep the dialog animations in place
	useEffect(() => {
		if (open) {
			setKey("open");
		} else {
			setTimeout(() => setKey("closed"), 300);
		}
	}, [open]);

	return (
		<DialogPrimitive.Root
			{...props}
			open={open}
			key={key}
			onOpenChange={setOpen}
		>
			{props.children}
		</DialogPrimitive.Root>
	);
};

export const DialogTrigger = DialogPrimitive.Trigger;
export const DialogClose = DialogPrimitive.Close;

export const DialogPortal = ({
	...props
}: DialogPrimitive.DialogPortalProps) => <DialogPrimitive.Portal {...props} />;
DialogPortal.displayName = DialogPrimitive.Portal.displayName;

const DialogOverlay = React.forwardRef<
	React.ElementRef<typeof DialogPrimitive.Overlay>,
	React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ className, ...props }, ref) => (
	<DialogPrimitive.Overlay
		className={cn(
			"data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 fixed inset-0 z-50 data-[state=closed]:animate-out data-[state=open]:animate-in",
			className,
		)}
		{...props}
		ref={ref}
	/>
));
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName;

const dialogVariants = cva(
	"fixed z-50 gap-4 bg-white shadow-lg transition ease-in-out data-[state=closed]:animate-out data-[state=open]:animate-in data-[state=closed]:duration-300 data-[state=open]:duration-300",
	{
		variants: {
			side: {
				top: "data-[state=closed]:slide-out-to-top data-[state=open]:slide-in-from-top inset-x-0 top-0 border-b",
				bottom:
					"data-[state=closed]:slide-out-to-bottom data-[state=open]:slide-in-from-bottom inset-x-0 bottom-0 border-t",
				left: "data-[state=closed]:slide-out-to-left data-[state=open]:slide-in-from-left inset-y-0 left-0 h-full w-96 max-w-full border-r sm:max-w-sm",
				right:
					"data-[state=closed]:slide-out-to-right data-[state=open]:slide-in-from-right inset-y-0 right-0 h-full w-96 max-w-full border-l sm:max-w-sm",
			},
			size: {
				default: "",
			},
		},
		defaultVariants: {
			side: "left",
		},
	},
);

type DialogContentProps = React.ComponentPropsWithoutRef<
	typeof DialogPrimitive.Content
> &
	VariantProps<typeof dialogVariants>;

export const DialogContent = React.forwardRef<
	React.ElementRef<typeof DialogPrimitive.Content>,
	DialogContentProps
>(({ side = "left", className, children, ...props }, ref) => (
	<DialogPortal>
		<DialogOverlay />
		<DialogPrimitive.Content
			ref={ref}
			className={cn(dialogVariants({ side }), className)}
			{...props}
		>
			{children}
		</DialogPrimitive.Content>
	</DialogPortal>
));

DialogContent.displayName = DialogPrimitive.Content.displayName;
