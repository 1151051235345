"use client";

import { graphql } from "@commerce-frontend/types";
import { cn } from "@commerce-frontend/ui/helpers/styles";
import { useClientGqlFetcher } from "@labdigital/graphql-fetcher";
import { sum } from "@labdigital/toolkit";
import { useQuery } from "@tanstack/react-query";
import { ShoppingCart } from "lucide-react";
import { Link } from "~/components/Link";
import { useStoreContext } from "~/hooks/useStoreContext";
import { getJWT } from "~/lib/auth/helpers";

const GetCartQuantity = graphql(/* GraphQL */ `
	query GetCartQuantity($storeContext: StoreContextInput!) {
		cart(storeContext: $storeContext) {
			lineItems {
				quantity
			}
		}
	}
`);

const useCartQuantity = () => {
	const storeContext = useStoreContext();
	const clientFetcher = useClientGqlFetcher();
	return useQuery({
		queryKey: ["cart", "quantity"],
		queryFn: () =>
			clientFetcher(GetCartQuantity, {
				storeContext,
			}),
		// Only run query if there's an active (anonymous) user
		enabled: Boolean(getJWT()),
		select: (data) => data.data?.cart,
	});
};

type Props = {
	className?: string;
};

/**
 * Shows a link to the cart and a badge with the number of items in the cart
 */
export const CartLink = ({ className }: Props) => {
	const { data } = useCartQuantity();
	const numCartItems = sum(data?.lineItems ?? [], (g) => g.quantity);

	return (
		<Link
			className={cn(
				"relative flex size-8 items-center justify-center hover:text-primary",
				className,
			)}
			href={{ pathname: "/cart" }}
			aria-label="cart"
		>
			<ShoppingCart className="size-6" />
			{numCartItems ? (
				<span className="-right-2 absolute top-0 rounded-full bg-primary px-2 py-0.5 font-bold text-white text-xs">
					{numCartItems > 999 ? "999+" : numCartItems}
				</span>
			) : undefined}
		</Link>
	);
};

export default CartLink;
