"use client";

import { Input } from "@commerce-frontend/ui/base/Input/Input";
import { Label } from "@commerce-frontend/ui/base/Label/Label";
import {
	FormField,
	FormMessage,
} from "@commerce-frontend/ui/components/Form/Form";
import { cn } from "@commerce-frontend/ui/helpers/styles";
import type { HTMLAttributes } from "react";
import { useId } from "react";
import { get, useFormContext } from "react-hook-form";
import { FormDescription } from "../FormDescription";
import { ServerError } from "../ServerError";

type Props = {
	name: string;
	placeholder?: string;
	description?: string;
	type?: string;
	label?: string;
	labelClassName?: string;
	inputClassName?: string;
	min?: string;
	max?: string;
	step?: "any" | number;
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
} & Omit<HTMLAttributes<HTMLDivElement>, "onChange" | "onBlur">;

export function InputField({
	label,
	description,
	name,
	type,
	placeholder,
	labelClassName,
	onChange,
	onBlur,
	inputClassName,
	max,
	min,
	step,
	...attributes
}: Props) {
	const id = useId();
	const errorId = `${id}:error`;
	const descriptionId = `${id}:description`;

	const { register, formState } = useFormContext();
	const error = get(
		formState.errors,
		name,
	) as (typeof formState.errors)[string];
	const invalid = Boolean(error?.message);
	const showError = formState.submitCount > 0 && error?.message;

	const getInputOptions = () => {
		switch (type) {
			case "number":
				return { valueAsNumber: true };
			case "date":
				return { valueAsDate: true };
			default:
				return {};
		}
	};

	return (
		<FormField {...attributes} aria-invalid={invalid}>
			{label && (
				<Label htmlFor={id} className={labelClassName}>
					{label}
				</Label>
			)}

			{description && (
				<FormDescription id={descriptionId}>{description}</FormDescription>
			)}

			<Input
				{...register(name, {
					onChange,
					onBlur,
					...getInputOptions(),
				})}
				type={type}
				id={id}
				className={cn(
					inputClassName,
					showError && "border-red-600 text-red-600",
				)}
				placeholder={placeholder}
				max={max}
				min={min}
				step={step}
				aria-errormessage={errorId}
				aria-describedby={description && descriptionId}
				aria-invalid={invalid}
			/>

			{showError && (
				<FormMessage id={errorId}>
					{/* @ts-expect-error - TODO: Fix typings in the future */}
					<ServerError error={error} />
				</FormMessage>
			)}
		</FormField>
	);
}
