import type { Pathnames } from "next-intl/routing";

export const locales = [
	"nl-NL",
	"nl-BE",
	"fr-BE",
	"en-GB",
	"de-DE",
	"it-IT",
	"es-ES",
	"fr-FR",
] as const;

export const localePrefix = "always"; // Default

// and external paths, separated by locale.
export const pathnames = {
	"/": "/",
	"/products/[slug]": "/products/[slug]",
	"/account/login": "/account/login",
	"/account/register": "/account/register",
	"/account/orders": "/account/orders",
	"/account/orders/[id]": "/account/orders/[id]",

	// Make sure this is also configured in the terraform files to forward the
	// `authToken` cookie to these paths.
	"/account": {
		"de-DE": "/konto",
		"en-GB": "/account",
		"es-ES": "/cuenta",
		"fr-BE": "/compte",
		"fr-FR": "/compte",
		"it-IT": "/account",
		"nl-BE": "/account",
		"nl-NL": "/account",
	},
	"/cart": {
		"de-DE": "/warenkorb",
		"en-GB": "/cart",
		"es-ES": "/carrito",
		"fr-BE": "/panier",
		"fr-FR": "/panier",
		"it-IT": "/carrello",
		"nl-BE": "/winkelwagen",
		"nl-NL": "/winkelwagen",
	},
	"/checkout/login": {
		"de-DE": "/kasse/anmelden",
		"en-GB": "/checkout/login",
		"es-ES": "/pago/iniciar-sesion",
		"fr-BE": "/paiement/connexion",
		"fr-FR": "/paiement/connexion",
		"it-IT": "/pagamento/accedi",
		"nl-BE": "/afrekenen/inloggen",
		"nl-NL": "/afrekenen/inloggen",
	},
	"/checkout/information": {
		"de-DE": "/kasse/informationen",
		"en-GB": "/checkout/information",
		"es-ES": "/pago/informacion",
		"fr-BE": "/paiement/informations",
		"fr-FR": "/paiement/informations",
		"it-IT": "/pagamento/informazioni",
		"nl-BE": "/afrekenen/informatie",
		"nl-NL": "/afrekenen/informatie",
	},
	"/checkout/payment": {
		"de-DE": "/kasse/zahlung",
		"en-GB": "/checkout/payment",
		"es-ES": "/pago/pago",
		"fr-BE": "/paiement/paiement",
		"fr-FR": "/paiement/paiement",
		"it-IT": "/pagamento/pagamento",
		"nl-BE": "/afrekenen/betaling",
		"nl-NL": "/afrekenen/betaling",
	},
	"/search": {
		"de-DE": "/suchen",
		"en-GB": "/search",
		"es-ES": "/buscar",
		"fr-BE": "/rechercher",
		"fr-FR": "/rechercher",
		"it-IT": "/ricerca",
		"nl-BE": "/zoeken",
		"nl-NL": "/zoeken",
	},
	"/product/[slug]": {
		"de-DE": "/product/[slug]",
		"en-GB": "/product/[slug]",
		"es-ES": "/product/[slug]",
		"fr-BE": "/product/[slug]",
		"fr-FR": "/product/[slug]",
		"it-IT": "/product/[slug]",
		"nl-BE": "/product/[slug]",
		"nl-NL": "/product/[slug]",
	},
	"/category/[...slug]": {
		"de-DE": "/kategorie/[...slug]",
		"en-GB": "/category/[...slug]",
		"es-ES": "/categoria/[...slug]",
		"fr-BE": "/categorie/[...slug]",
		"fr-FR": "/categorie/[...slug]",
		"it-IT": "/categoria/[...slug]",
		"nl-BE": "/categorie/[...slug]",
		"nl-NL": "/categorie/[...slug]",
	},
} satisfies Pathnames<typeof locales>;
