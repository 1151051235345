import { snakeCase } from "change-case";

type ComponentFragment = {
	__typename: string;
	id: string;
};

/**
 * These data attributes are used by the Storyblok preview functionality to make the component selectable.
 *
 * The blok.id is the uid of the blok.
 */
export const storyblokDataAttributes = (
	blok: ComponentFragment,
): { "data-component-id": string; "data-component-type": string } => ({
	"data-component-id": blok.id,
	"data-component-type": snakeCase(blok.__typename),
});
