type ProductDetailHref = {
	pathname: "/product/[slug]";
	params: {
		slug: string;
	};
	query?: {
		sku: string | null | undefined;
	};
};

/**
 * Creates a link to a product detail page with an optional SKU query parameter.
 * @param slug The slug for the page
 * @param sku Optional SKU query parameter
 * @returns A specific link object with correct pathname set for next-intl type inference
 */
export const productDetailHref = (
	slug: string,
	sku?: string | null,
): ProductDetailHref => ({
	pathname: "/product/[slug]",
	params: {
		slug,
	},
	query: sku ? { sku } : undefined,
});

type CategoryHref = {
	pathname: "/category/[...slug]";
	params: {
		slug: string[];
	};
};

/**
 * Creates a link to a category page with a slug array.
 * @param slug The slug array for the page
 * @returns A specific link object with correct pathname set for next-intl type inference
 */
export const categoryHref = (slug: string[]): CategoryHref => ({
	pathname: "/category/[...slug]",
	params: {
		slug,
	},
});
