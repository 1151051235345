"use client";

import { cn } from "@commerce-frontend/ui/helpers/styles";
import { User, UserCheck } from "lucide-react";
import { useTranslations } from "next-intl";
import { Link } from "~/components/Link";
import { useAuth } from "~/lib/auth/auth-provider";

type Props = {
	className?: string;
};

/**
 * Component that renders an account link in the header and shows the current state of being logged in or not
 */
export const AccountLink = ({ className }: Props) => {
	const t = useTranslations("AccountLink");
	const { isAuthenticated } = useAuth();

	return (
		<Link
			className={cn(
				"flex size-8 shrink-0 items-center justify-center hover:text-primary lg:w-auto lg:min-w-8",
				className,
			)}
			href={{ pathname: isAuthenticated ? "/account" : "/account/login" }}
			aria-label={t("account")}
		>
			{isAuthenticated ? (
				<UserCheck className="size-6" />
			) : (
				<User className="size-6" />
			)}
		</Link>
	);
};
