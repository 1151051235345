import Image from "next/image";

type Props = {
	locale: Intl.Locale;
	className?: string;
	width?: number;
	height?: number;
};

export const IconFlag = (args: Props) => (
	<Image
		className={args.className ?? "h-5 w-7 rounded"}
		src={flagPath(args.locale)}
		width={args.width ?? "28"}
		height={args.height ?? "20"}
		alt=""
		unoptimized
	/>
);

const flagPath = (locale: Intl.Locale) => {
	const name = locale.region?.toLowerCase() ?? locale.language.toLowerCase();
	return `/icons/flags/${name}.svg`;
};
