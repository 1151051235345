import type { ResultOf } from "@graphql-typed-document-node/core";
import { useTranslations } from "next-intl";
import { getMenuItemLabel, getMenuItemURL } from "~/lib/helpers/menuLink";
import type { TopMenuItemFragment } from "../Navigation.fragments";
import Collapse from "./Collapse";
import LinkTile from "./LinkTile";
import TopBar from "./TopBar";

type SubMenuProps = {
	topMenuItem: ResultOf<typeof TopMenuItemFragment>;
	onBack: () => void;
};

export const SubMenu = ({ topMenuItem: mainItem, onBack }: SubMenuProps) => {
	const t = useTranslations("SubMenu");

	return (
		<>
			<TopBar onBack={onBack} backLabel={mainItem.label} />

			<div className="max-h-screen overflow-y-scroll">
				{/* A main item with a submenu */}
				{mainItem.subMenuItems?.map((subMenuItem) =>
					// A submenu item with further submenu items
					subMenuItem.subMenuItems?.length ? (
						<Collapse
							initialOpen={mainItem.subMenuItems?.length === 1}
							label={getMenuItemLabel(subMenuItem)}
							key={subMenuItem.label}
						>
							<div>
								{/* Submenu items of the submenu */}
								{subMenuItem.subMenuItems?.map((subMenuLink) => (
									<LinkTile
										key={subMenuLink.label}
										label={getMenuItemLabel(subMenuLink)}
										url={getMenuItemURL(subMenuLink)}
										indent
									/>
								))}
								{/* All link of the submenu */}
								{subMenuItem.target?.__typename === "ResourceLink" &&
									subMenuItem.target?.url && (
										<LinkTile
											label={t("all-link", {
												label: mainItem.label?.toLowerCase(),
											})}
											url={subMenuItem.target.url}
											indent
											strong
										/>
									)}
								{subMenuItem.target?.__typename === "ProductCategory" &&
									subMenuItem.target?.path && (
										<LinkTile
											label={t("all-link", {
												label: mainItem.label?.toLowerCase(),
											})}
											url={subMenuItem.target.name ?? subMenuItem.label}
											indent
											strong
										/>
									)}
							</div>
						</Collapse>
					) : (
						// A submenu item without further submenu items
						<LinkTile
							key={subMenuItem.label}
							url={getMenuItemURL(subMenuItem)}
							label={getMenuItemLabel(subMenuItem)}
						/>
					),
				)}

				{/* all link for main item */}
				{mainItem.target?.__typename === "ResourceLink" &&
				mainItem.target?.url ? (
					<LinkTile
						label={t("all-link", {
							label: mainItem.label?.toLowerCase(),
						})}
						url={mainItem.target.url}
						strong
					/>
				) : undefined}
			</div>
		</>
	);
};
