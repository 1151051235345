import type { Locale } from "~/lib/i18n/types";

type Money = {
	centAmount: number;
	currency: string;
};

export type MoneyParts = {
	currency: string;
	integer: string;
	decimal: string;
	fraction: string;
};

// TODO: This function is silly, we need to do this better
export const getMoneyParts = (money: Money, locale?: Locale): MoneyParts => {
	const parts = new Intl.NumberFormat(locale, {
		minimumFractionDigits: 2,
		currency: money.currency,
		style: "currency",
	}).formatToParts(money.centAmount / 100);

	const integerValues = parts
		.filter((p) => p.type === "integer")
		.map((p) => p.value)
		.join("");

	return {
		currency: parts.find((p) => p.type === "currency")?.value ?? "",
		integer: integerValues,
		decimal: parts.find((p) => p.type === "decimal")?.value ?? "",
		fraction: parts.find((p) => p.type === "fraction")?.value ?? "",
	};
};

export const getRawMoneyParts = (
	money: Money,
	locale?: Locale,
): Intl.NumberFormatPart[] => {
	const parts = new Intl.NumberFormat(locale, {
		minimumFractionDigits: 2,
		currency: money.currency,
		style: "currency",
	}).formatToParts(money.centAmount / 100);

	return parts;
};

export const formatMoney = (money: Money, locale?: string): string =>
	new Intl.NumberFormat(locale, {
		style: "currency",
		minimumFractionDigits: 2,
		currency: money.currency,
	}).format(money.centAmount / 100);
