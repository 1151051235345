import { cn } from "@commerce-frontend/ui/helpers/styles";
import { Link, type LinkProps } from "~/components/Link/Link";

type Props = {
	url: LinkProps["href"];
	label: React.ReactNode;
	strong?: boolean;
	className?: string;
	indent?: boolean;
};

const LinkTile = ({ url, label, strong, indent, className }: Props) => (
	<Link
		href={url}
		className={cn(
			"block border-gray-200 border-b px-6 py-5 hover:text-brand",
			strong ? "font-medium" : null,
			indent ? "pl-14" : null,
			className,
		)}
	>
		{label}
	</Link>
);

export default LinkTile;
