import type { LinkProps } from "~/components/Link/Link";
import { categoryHref } from "../link-builder";

type Props = {
	__typename: "MenuItem" | "MenuLink";
	id: string;
	label?: string | null | undefined;
	target?:
		| { __typename: "ProductCategory"; name: string; path: string }
		| { __typename: "ResourceLink"; url: string }
		| null
		| undefined;
};

export const getMenuItemURL = (item: Props): LinkProps["href"] => {
	if (!item.target) {
		return "";
	}

	if (item.target.__typename === "ProductCategory") {
		const path = item.target.path.split("/");
		if (path.length > 0) {
			return categoryHref(path);
		}
	}
	if (item.target.__typename === "ResourceLink") {
		return item.target.url ?? "";
	}

	return "";
};

export const getMenuItemLabel = (item: Props): string => {
	if (!item.target) {
		return "";
	}

	if (item.target.__typename === "ProductCategory") {
		return item.target.name ?? item.label ?? "";
	}
	if (item.target.__typename === "ResourceLink") {
		return item.label ?? "";
	}

	return "";
};
