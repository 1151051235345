"use client";
import NextLink from "next/link";
import type { ComponentProps } from "react";
import { forwardRef, useState } from "react";
import type { pathnames } from "~/lib/i18n/config";
import { Link as IntlLink } from "~/lib/i18n/navigation";

type Pathnames = keyof typeof pathnames;

export type LinkProps = Omit<
	ComponentProps<typeof IntlLink<Pathnames>>,
	"href"
> & {
	// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents -- We allow this for now to use intellisense
	href: string | ComponentProps<typeof IntlLink<Pathnames>>["href"];
	newTab?: boolean;
	"data-testid"?: string;
};

export const Link = forwardRef<HTMLAnchorElement, LinkProps>(function Link(
	{ newTab, href, ...rest },
	ref,
) {
	const [prefetch, setPrefetch] = useState(false);

	let prefetchTimeout: NodeJS.Timeout | null = null;
	const prefetchArgs = {
		onMouseEnter: () => {
			prefetchTimeout = setTimeout(() => {
				setPrefetch(true);
			}, 150);
		},
		onMouseLeave: () => {
			if (prefetchTimeout) {
				clearTimeout(prefetchTimeout);
				prefetchTimeout = null;
			}
			setPrefetch(false);
		},
		onTouchStart: () => setPrefetch(true),
		onTouchEnd: () => setPrefetch(false),
	};

	if (typeof href === "string") {
		const isExternal = /^https?:/.test(href);

		return (
			<NextLink
				href={href}
				{...rest}
				{...prefetchArgs}
				prefetch={prefetch}
				target={newTab || isExternal ? "_blank" : undefined}
				rel={newTab || isExternal ? "noreferrer" : undefined}
				ref={ref}
			/>
		);
	}
	return (
		<IntlLink
			href={href}
			ref={ref}
			prefetch={prefetch}
			{...prefetchArgs}
			{...rest}
		/>
	);
});
