type ComponentFragment = {
	__typename: string;
	id: string;
};

/**
 * These data attributes are used by the Storyblok preview functionality to make the component selectable.
 *
 * The blok.id is the uid of the blok.
 */

type ReturnProps = {
	"data-contentful-field-id": string;
	"data-contentful-entry-id": string;
	"data-contentful-locale": string;
};

export const contentfulDataAttributes = (
	blok: ComponentFragment,
): ReturnProps => ({
	"data-contentful-entry-id": blok.id,
	"data-contentful-field-id": "foobar",
	"data-contentful-locale": "en-GB",
});
