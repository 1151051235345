import type { ResultOf } from "@graphql-typed-document-node/core";
import { useTranslations } from "next-intl";
import Image from "next/image";
import { getMenuItemLabel, getMenuItemURL } from "~/lib/helpers/menuLink";
import type { TopMenuItemFragment } from "../Navigation.fragments";
import type { HeaderInfoPagesFragment } from "../TopBar";
import ActionTile from "./ActionTile";
import LinkTile from "./LinkTile";
import TopBar from "./TopBar";

type Props = {
	topMenuItems: ResultOf<typeof TopMenuItemFragment>[];
	onMainMenuItemSelect: (item: ResultOf<typeof TopMenuItemFragment>) => void;
	onSelectLanguage: () => void;
	infoPages?: ResultOf<typeof HeaderInfoPagesFragment>["infoPages"];
	locale: string;
};

export const MainMenu = ({
	topMenuItems,
	onMainMenuItemSelect,
	onSelectLanguage,
	infoPages,
	locale: localeString,
}: Props) => {
	const t = useTranslations("MainMenu");
	const locale = new Intl.Locale(localeString);
	return (
		<>
			<TopBar />

			<nav className="max-h-screen flex-1 overflow-y-scroll">
				{topMenuItems.map((mainMenuItem) =>
					mainMenuItem.__typename === "MenuItem" ? (
						<ActionTile
							icon="forward"
							key={mainMenuItem.label}
							label={getMenuItemLabel(mainMenuItem)}
							className="font-bold"
							trigger={() => onMainMenuItemSelect(mainMenuItem)}
							strong
						/>
					) : (
						<LinkTile
							key={mainMenuItem.label}
							url={getMenuItemURL(mainMenuItem)}
							label={getMenuItemLabel(mainMenuItem)}
							className="font-bold"
							strong
						/>
					),
				)}

				<div className="h-16 border-gray-200 border-b" />

				{infoPages?.service?.name && (
					<LinkTile
						url={infoPages.service.url}
						label={infoPages.service.name}
					/>
				)}
				{infoPages?.business?.name && (
					<LinkTile
						url={infoPages.business.url}
						label={infoPages.business.name}
					/>
				)}

				<ActionTile
					label={
						locale ? (
							<span className="flex w-full items-center gap-3 hover:underline">
								<Image
									className="h-5 w-7 rounded object-cover"
									src={`/icons/flags/${locale.region?.toLowerCase() ?? locale.language.toLowerCase()}.svg`}
									width="0"
									height="0"
									alt=""
									unoptimized
								/>

								<span>
									{t("language")}:{" "}
									{new Intl.DisplayNames([locale], {
										type: "language",
									}).of(locale.language)}
								</span>
							</span>
						) : (
							""
						)
					}
					trigger={onSelectLanguage}
				/>

				<LinkTile url="/account" label={t("sign-in")} />
			</nav>
		</>
	);
};
