// Auth context for the site
"use client";

import {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useState,
} from "react";
import { deleteAuthCookies } from "./actions";
import { type Token, checkLocalToken, getAccessToken, getJWT } from "./helpers";

type AuthContextType = {
	isAuthenticated: boolean;
	user: { firstName: string; lastName: string } | null;
	loading: boolean;
	logout: () => Promise<void>;
	validateLocalToken: () => void;
	checkToken: () => Promise<void>;
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

/**
 * Provider that handles authentication state.
 *
 * @remarks
 * This component manages the client side authentication state and flow for the application.
 *
 * @flow
 * 1. Component Mount:
 *    - The `validateLocalToken` function is called.
 *    - It checks the local token without making a request.
 *    - The auth state is updated based on the local token.
 *
 * 2. Request Handling:
 *    - Before making a request, `checkToken` is called to verify token status with the server.
 *    - If the access token is expired or invalid:
 *      a. The server attempts to use the refresh token to obtain a new access token.
 *      b. If successful, a new access token is returned and the auth state is updated.
 *      c. If unsuccessful, the auth state is updated to unauthenticated.
 *    - The request is then made with the current token state.
 *    - The auth state is updated based on the server's response.
 *
 * @param props.apiHostname - api hostname used to fetch token status from the api
 */
export function AuthProvider({
	children,
	apiHostname,
}: { children: React.ReactNode; apiHostname: string }) {
	const [authState, setAuthState] = useState<
		Omit<AuthContextType, "logout" | "validateLocalToken" | "checkToken">
	>({
		isAuthenticated: false,
		user: null,
		loading: true,
	});

	const updateAuthState = useCallback((token?: Token) => {
		if (token?.authenticated) {
			setAuthState({
				isAuthenticated: true,
				user: {
					firstName: token.firstName || "",
					lastName: token.lastName || "",
				},
				loading: false,
			});
		} else {
			setAuthState({ isAuthenticated: false, user: null, loading: false });
		}
	}, []);

	/**
	 * All this does is validate the local token in the cookie, it will not do any request to the server.
	 * This is best used right after a request so that we can check if the token is still valid afterwards.
	 *
	 * @remarks Valid use case is that the API does not accept the given cookie and empties it as a response
	 */
	const validateLocalToken = useCallback(() => {
		const token = checkLocalToken();
		updateAuthState(token);
	}, [updateAuthState]);

	/**
	 * Load initial token when mounting the application
	 * Doesn't do any checks because we don't want to hammer the server when the user first loads the application
	 *
	 * It will get checked when the user makes a request.
	 */
	const loadToken = useCallback(() => {
		const token = getJWT();
		updateAuthState(token);
	}, [updateAuthState]);

	/**
	 * Checks the token status with the server.
	 *
	 * - If the access token is expired or invalid, the server attempts to use the refresh token to obtain a new access token.
	 * - If successful, a new access token is returned and the auth state is updated.
	 * - If unsuccessful, the auth state is updated to unauthenticated.
	 */
	const checkToken = useCallback(async () => {
		const token = await getAccessToken(apiHostname);
		updateAuthState(token);
	}, [apiHostname, updateAuthState]);

	// Load initial auth state when mounting the application
	useEffect(() => {
		loadToken();
	}, [loadToken]);

	/**
	 * Log out the user
	 *
	 * @throws {Error} If the COOKIE_DOMAIN environment variable is not set. Catch this function and handle it in the UI.
	 */
	const logout = async () => {
		// Handle cookie removal in server action
		await deleteAuthCookies();

		updateAuthState(undefined);
	};

	return (
		<AuthContext.Provider
			value={{
				...authState,
				logout,
				validateLocalToken,
				checkToken,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
}

/**
 * Custom hook to access authentication context.
 *
 * @returns {Object} An object containing:
 *   - isAuthenticated: boolean indicating if the user is authenticated
 *   - user: object containing user information (firstName, lastName) or null if not authenticated
 *   - loading: boolean indicating if the authentication state is being loaded
 *   - logout: function to log out the current user
 *   - refreshToken: function to refresh the authentication token
 *
 * @throws {Error} If used outside of an AuthProvider
 *
 * @example
 * const { isAuthenticated, user, logout } = useAuth();
 *
 * if (isAuthenticated) {
 *   console.log(`Welcome, ${user.firstName}!`);
 * } else {
 *   console.log('Please log in.');
 * }
 */
export function useAuth() {
	const context = useContext(AuthContext);
	if (context === undefined) {
		throw new Error("useAuth must be used within an AuthProvider");
	}
	return context;
}
