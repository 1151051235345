import { createLocalizedPathnamesNavigation } from "next-intl/navigation";
import { defineRouting } from "next-intl/routing";
import type { ComponentProps } from "react";
import { locales, pathnames } from "./config";
import type { Locale } from "./types";
export { useRouter as useUnsafeRouter } from "next/navigation";

export const isValidLocale = (locale: string): locale is Locale =>
	(locales as readonly string[]).includes(locale);

/**
 * Used for type casting the language param to a locale
 * @param localeParam
 * @returns The same string but typed as a locale
 */
export const getLocaleFromParams = (
	localeParam: string,
	defaultLocale: Locale,
): Locale => (isValidLocale(localeParam) ? localeParam : defaultLocale);

export const routing = defineRouting({
	locales: locales,
	defaultLocale: "nl-NL",
	localePrefix: {
		mode: "always",
		prefixes: {
			"nl-NL": "/nl",
			"nl-BE": "/be/nl",
			"fr-BE": "/be/fr",
			"en-GB": "/en",
		},
	},
	pathnames: pathnames as typeof pathnames & Record<string & {}, string>,
});

export const { Link, redirect, usePathname, useRouter, getPathname } =
	createLocalizedPathnamesNavigation(routing);

export type HrefTarget = ComponentProps<
	typeof Link<keyof typeof pathnames>
>["href"];
