"use client";

import { Button } from "@commerce-frontend/ui/base/Button";
import { emailField } from "@commerce-shared/validation";
import { useTranslations } from "next-intl";
import { z } from "zod";
import { Form } from "~/components/FormField/Form";
import { InputField } from "~/components/FormField/InputField/InputField";
import { useForm } from "~/components/FormField/useForm";

type Values = z.infer<typeof subscribeSchema>;

export const subscribeSchema = z.object({
	email: emailField,
});

export const NewsletterForm = () => {
	const t = useTranslations("NewsletterForm");
	const formT = useTranslations("Form");
	const form = useForm(subscribeSchema);

	const onSubmit = (values: Values) => console.info(values);

	return (
		<Form
			className="flex flex-col items-start gap-4"
			form={form}
			onSubmit={onSubmit}
		>
			<InputField
				className="w-full shrink-0 lg:w-auto"
				name="email"
				placeholder={formT("email-label")}
				inputClassName="border bg-white"
			/>
			<Button type="submit" className="rounded-full px-6" variant="primary">
				{t("subscribe")}
			</Button>
		</Form>
	);
};
