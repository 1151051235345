import { useEffect, useState } from "react";

const defaultScreens: Record<string, string> = {
	sm: "640px",
	md: "768px",
	lg: "1024px",
	xl: "1280px",
	xxl: "1536px",
};

/*
 */
class WindowSize {
	// // 0 - sm = mobile
	// static sm = parseInt(SCREENS.sm);
	// // sm - md = tablet portrait
	// static md = parseInt(SCREENS.md);
	// // md - lg = tablet landscape
	// static lg = parseInt(SCREENS.lg);
	// // lg - xl = desktop
	// static xl = parseInt(SCREENS.xl);
	// // xl - xxl = big desktop
	// static xxl = parseInt(SCREENS["2xl"]);

	constructor(
		public width: number,
		public height: number,
		public screens: Record<string, string> = defaultScreens,
	) {}

	private getScreenSize(size: string) {
		return Number.parseInt(this.screens[size] ?? defaultScreens[size] ?? "0");
	}

	get upToMobile() {
		return this.width < this.getScreenSize("sm");
	}
	get upToTabletPortrait() {
		return this.width < this.getScreenSize("md");
	}
	get upToTabletLandscape() {
		return this.width < this.getScreenSize("lg");
	}
	get upToDesktop() {
		return this.width < this.getScreenSize("xl");
	}
	get atLeastTabletPortrait() {
		return this.width >= this.getScreenSize("sm");
	}
	get atLeastTabletLandscape() {
		return this.width >= this.getScreenSize("md");
	}
	get atLeastDesktop() {
		return this.width >= this.getScreenSize("lg");
	}
	get atLeastBigDesktop() {
		return this.width >= this.getScreenSize("xl");
	}
}

export const useWindowSize = (
	screens: Record<string, string> = defaultScreens,
) => {
	const [state, setState] = useState<WindowSize>(
		new WindowSize(1600, 1600, screens),
	);

	useEffect(() => {
		const handler = () => {
			setState(
				new WindowSize(
					document.body.clientWidth,
					document.body.clientHeight,
					screens,
				),
			);
		};
		window.addEventListener("resize", handler);
		handler();
		return () => window.removeEventListener("resize", handler);
	}, []);

	return state;
};
