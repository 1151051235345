import { contentfulDataAttributes } from "@commerce-frontend/contentful-preview/component";
import { getPreviewID as getPreviewIDContentful } from "@commerce-frontend/contentful-preview/server";
import { storyblokDataAttributes } from "@commerce-frontend/storyblok-preview/component";
import { getPreviewID as getPreviewIDStoryblok } from "@commerce-frontend/storyblok-preview/server";

type Attributes = {
	__typename: string;
	id: string;
};

const EVOLVE_CMS = process.env.NEXT_PUBLIC_EVOLVE_CMS ?? "contentful";

export const cmsPreviewAttributes = (data: Attributes) => {
	switch (EVOLVE_CMS) {
		case "contentful":
			return contentfulDataAttributes(data);
		case "storyblok":
			return storyblokDataAttributes(data);
	}
};

export const getContentPreviewID = () => {
	switch (EVOLVE_CMS) {
		case "contentful":
			return getPreviewIDContentful();
		case "storyblok":
			return getPreviewIDStoryblok();
	}
};
